import React from 'react';
import { URL } from '../variables';
import Carousel from 'react-bootstrap/Carousel'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, Route, Redirect } from 'react-router-dom';
import Switch from 'react-bootstrap/esm/Switch';
import Inicial from '../PaginaInicial/Inicial';
import Inscricao from '../PaginaInicial/Cadastro';
import Processos from '../processos/Processos';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import YouTubeIcon from '@material-ui/icons/YouTube';

import styles from "./template.module.css";

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    }
  />
);

class Template extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:false
    }
  }

  

  // renderRedirect = () => {
  //   if (this.state.redirect) {
  //     return <Redirect to={'/'+this.state.faculdade_select.slug} />
  //   }
  // }



  render() {
    console.log(this.props.match.url)
    return (
      <div >
        {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
          <span className="sr-only">Loading...</span>
        </div></div>}
        {this.state.loading == false && <div >
          <Navbar bg="white" className="d-flex" expand="lg">
            <Navbar.Brand href="#home" style={{ marginLeft: '1rem', float: "left" }}><img src={require('../assets/images/logo-itec-dark.png')} height="50" alt="logo" /></Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            {/* <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto mr-5">
                <Nav.Link href="/login" style={{}}>Área do Candidato</Nav.Link>
              </Nav>

            </Navbar.Collapse> */}
          </Navbar>
          <Switch style={{paddingLeft:0}}>
              
              {/* <Route exact path={`${this.props.match.url}`} render={(props2) => <Inicial {...props2} />} /> */}
              <Route exact path={`${this.props.match.url}`} render={(props2) => { return(<Inscricao {...props2} />)}} />
              {/* <Route exact path={`${this.props.match.url}processo/:slug`} render={(props2) => { return(<Processos {...props2} />)}} /> */}
                
                    

              {/* <OtherRoute path="*" path_name='/' /> */}

          </Switch>
          <footer style={{position: "absolute", width: "100%", height: "fit-content",backgroundColor:'#0a3e7c', zIndex: -2}}>
            <div className="container">
              <div className="row mt-5">
                <div className="col-6">
                  <h4 style={{whiteSpace:'pre-line', color:'white', fontWeight: 700}}>
                    Contato Itec Mais
                  </h4>
                  <p style={{whiteSpace:'pre-line',marginBottom: "2px", color: "white"}}>
                    E-mail: contato@itec.edu.br
                  </p>
                  <p style={{whiteSpace:'pre-line', color: "white"}}>
                    Horário de Atendimento: Segunda à sexta-feira das 8hrs às 18hrs (Horário de Brasília)
                  </p>
                  <p style={{whiteSpace:'pre-line', color: "white"}}>
                    Telefones: (83) 3421-1936 / (83) 99831-3435
                  </p>

              </div>
                <div className="col-6">
                  <h4 style={{whiteSpace:'pre-line', color:'white', fontWeight: 700}}>
                    Social
                  </h4>
                  <div className={styles.icons}>
                    <div className={styles.facebook}>
                      <a href="https://www.facebook.com/itecpatos" target="_blank">
                        <FacebookIcon style={{ color: "#1877f2" }}/>
                      </a>
                    </div>
                    <div className={styles.instagram}>
                      <a href="https://www.instagram.com/itecmais/" target="_blank">
                        <InstagramIcon style={{ color: "white", zIndex: 1 }}/>
                      </a>
                    </div>
                    <div className={styles.whatsapp}>
                      <a href="https://api.whatsapp.com/send?phone=5583993595345" target="_blank">
                        <WhatsAppIcon style={{ color: "white" }}/>
                      </a>
                    </div>
                    <div className={styles.youtube}>
                      <a href="https://www.youtube.com/channel/UC1c0Lf9RSne0g6kU7IOMsIQ" target="_blank">
                        <YouTubeIcon style={{ color: "#ff0000" }}/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p style={{whiteSpace:'pre-line',marginBottom:0, color: "#c4c4c4", textAlign: "center"}}>
                  Copyright 2021 © ITEC Mais - Instituto Tecnológico da Paraíba
                </p>
              </div>
            </div>

          </footer>
        </div>}
      </div>
    );
  }


}

export default Template;
