
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../actions/AppActions';
import { connect } from 'react-redux';
import JoditEditor from "jodit-react";

// users
import { URL } from '../variables';
import ReactPaginate from 'react-paginate';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker,
    TimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import moment from 'moment';

class CriarProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            msgErro: '',

            path: '/painel/processos',
            loading_screen: false,

            name: '',
            name_faculdade: '',
            logo_faculdade: '',
            logo_path: '',
            descricao: '',
            loading_save: false,
            data_inicio: moment(),
            data_fim: moment().add('months', 2),


            horario_inicio: moment(),
            horario_fim: moment().add('hours', 2),

        };
        this.config = {
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            language: 'pt_br'
        }
    }



    salvar() {
        let data = {
            name: this.state.name,
            name_faculdade: this.state.name_faculdade,
            logo_faculdade: this.state.logo_faculdade,
            descricao: this.state.descricao,
            inicio: this.state.data_inicio.format('YYYY-MM-DD') + ' ' + this.state.horario_inicio.format('HH:mm'),
            fim: this.state.data_fim.format('YYYY-MM-DD') + ' ' + this.state.horario_fim.format('HH:mm'),
        };
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('name_faculdade', this.state.name_faculdade);
        form.append('logo_faculdade', this.state.logo_faculdade);
        form.append('descricao', this.state.descricao);
        form.append('inicio', this.state.data_inicio.format('YYYY-MM-DD') + ' ' + this.state.horario_inicio.format('HH:mm'));
        form.append('fim', this.state.data_fim.format('YYYY-MM-DD') + ' ' + this.state.horario_fim.format('HH:mm'));

        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/salvar_processo`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Processos Seletivos');

    }

    get_areas() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_areas`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_screen: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let area = '';
                    if (resp.areas_concentracao.length > 0) {
                        area = resp.areas_concentracao[0].id
                    }
                    this.setState({ loading_screen: false, areas_concentracao: resp.areas_concentracao, area: area });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_screen: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_screen: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }







    render() {
        console.log(this.state.logo_faculdade);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar Processos</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre os processos seletivos do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome da Instituição</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome da Instituição" type="text" onChange={(e) => this.setState({ name_faculdade: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Logo da Instituição</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" accept="image/*" placeholder="Logo da processo" type="file" onChange={(e) => {
                                                    this.setState({ logo_faculdade: e.target.files[0] });
                                                    var file = e.target.files[0]
                                                    let reader = new FileReader()
                                                    reader.readAsDataURL(file)
                                                    reader.onload = () => {
                                                        this.setState({
                                                            logo_path: reader.result
                                                        })
                                                    };
                                                    reader.onerror = function (error) {
                                                        console.log('Error: ', error);
                                                    }
                                                }} />

                                            </div>
                                            {this.state.logo_path != '' && <div className="col-sm-12 mt-3" >
                                                <div style={{ border: '1px solid lightgrey', borderRadius: 5 }}>
                                                    <img style={{ height: '300px', width: '100%', objectFit: 'contain' }} src={this.state.logo_path} />
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome do processo</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do processo" type="text" onChange={(e) => this.setState({ name: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição do Processo</label>
                                            <div className="col-sm-12">
                                                <JoditEditor
                                                    // ref={editor}
                                                    value={this.state.descricao}
                                                    config={this.config}
                                                    tabIndex={1} // tabIndex of textarea
                                                    // onBlur={newContent => this.setState({descricao:newContent})} // preferred to use only this option to update the content for performance reasons
                                                    onChange={newContent => { console.log(newContent); this.setState({ descricao: newContent }); }}
                                                />
                                            </div>

                                        </div>

                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label className="col-form-label">Data de Ínicio das Inscrições</label>

                                                    <DatePicker
                                                        autoOk
                                                        margin="normal"
                                                        style={{ width: '100%' }}
                                                        invalidDateMessage="Data inválida"
                                                        ampm={false}
                                                        minDate={moment()}
                                                        id="time-picker"
                                                        cancelLabel="Cancelar"
                                                        clearLabel="Limpar"
                                                        format="DD/MM/YYYY"
                                                        value={this.state.data_inicio}
                                                        onChange={(date) => { this.setState({ data_inicio: date }) }}

                                                    />

                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="col-form-label">Data de Finalização das Inscrições</label>

                                                    <DatePicker
                                                        autoOk
                                                        margin="normal"
                                                        style={{ width: '100%' }}
                                                        invalidDateMessage="Data inválida"
                                                        ampm={false}
                                                        minDate={moment()}
                                                        id="time-picker"
                                                        cancelLabel="Cancelar"
                                                        clearLabel="Limpar"
                                                        format="DD/MM/YYYY"
                                                        value={this.state.data_fim}
                                                        onChange={(date) => { this.setState({ data_fim: date }) }}

                                                    />

                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="col-form-label">Horário de Ínicio</label>

                                                    <TimePicker
                                                        autoOk
                                                        margin="normal"
                                                        style={{ width: '100%' }}
                                                        invalidDateMessage="Horário inválido"
                                                        ampm={false}
                                                        id="time-picker"
                                                        cancelLabel="Cancelar"
                                                        clearLabel="Limpar"
                                                        label=""
                                                        value={this.state.horario_inicio}
                                                        onChange={(date) => { this.setState({ horario_inicio: date }) }}

                                                    />

                                                </div>
                                                <div className="col-sm-6">
                                                    <label className=" col-form-label">Horário de Encerramento</label>

                                                    <TimePicker
                                                        autoOk
                                                        style={{ width: '100%' }}
                                                        margin="normal"
                                                        invalidDateMessage="Horário inválido"
                                                        ampm={false}
                                                        id="time-picker"
                                                        cancelLabel="Cancelar"
                                                        clearLabel="Limpar"
                                                        label=""
                                                        value={this.state.horario_fim}
                                                        onChange={(date) => { console.log(date); this.setState({ horario_fim: date }) }}

                                                    />
                                                </div>
                                            </div>
                                        </MuiPickersUtilsProvider>




                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/painel/processos"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar processo</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(CriarProcesso);


