
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// users
import moment from 'moment';
import { URL } from '../variables';
import ReactPaginate from 'react-paginate';
import CursosProcesso from './CursosProcesso';

class VerProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            processo: {},
            msgErro: '',
            tab: 'cursos'
        };
    }






    componentDidMount() {

        this.get_curso(this.props.match.params.id);
    }

    get_curso(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_processo/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_screen: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    this.props.mudarTitle(resp.processo.name);
                    // this.props.mudarUser(resp.user);
                    this.setState({ processo: resp.processo, loading_screen: false });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_screen: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_screen: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="float-right">
                                <Link to={"/painel/processos"}><span
                                    className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                            </div>

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-4">
                                    <div className="col-12 ">
                                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.state.processo.name}</h4>
                                    </div>


                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 ">

                                        <br />
                                        <br />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.processo.descricao }}>

                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-2">
                                    
                                    <div className="col-sm-12 mt-3 mb-3" >
                                        <div style={{ border: '1px solid lightgrey', borderRadius: 5 }}>
                                            <img style={{ height: '300px', width: '100%', objectFit: 'contain' }} src={URL+'storage/'+ this.state.processo.logo_faculdade} />
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '0.5rem' }}><b>Instituição:</b> {this.state.processo.name_faculdade}</p>
                                    </div>
                                    <div className="col-12 ">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '0.5rem' }}><b>Número de candidatos inscritos:</b> {this.state.processo.candidatos}</p>
                                    </div>

                                    <div className="col-12 ">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '0.5rem' }}><b>Data de Início das Inscrições:</b> {this.state.processo.inicio}</p>
                                    </div>
                                    <div className="col-12 ">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '0.5rem' }}><b>Data de Enceramento das Inscrições:</b> {this.state.processo.fim}</p>
                                    </div>

                                </div>

                                <div className="row mb-2 mt-4 mb-4">

                                    <div className="col-sm-6 col-12" style={{ padding: 0 }}>
                                        <div onClick={() => this.setState({ tab: 'cursos' })} style={{ height: 55, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid grey', borderBottom: this.state.tab == 'cursos' ? 0 : '1px solid lightgrey', borderTopLeftRadius: 5, borderTopRightRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff', backgroundColor: this.state.tab == 'cursos' ? 'white' : 'lightgrey' }}>
                                            <i className="mdi mdi-book-open-variant" style={{ fontSize: '1.6rem', marginRight: '1rem' }}></i>
                                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.1rem', color: 'black' }}>Cursos</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12" style={{ padding: 0 }}>
                                        <div onClick={() => this.setState({ tab: 'candidatos' })} style={{ height: 55, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid grey', borderBottom: this.state.tab == 'candidatos' ? 0 : '1px solid lightgrey', borderTopLeftRadius: 5, borderTopRightRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff', backgroundColor: this.state.tab == 'candidatos' ? 'white' : 'lightgrey' }}>
                                            <i className="mdi mdi-bookmark-check" style={{ fontSize: '1.6rem', marginRight: '1rem' }}></i>
                                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.1rem', color: 'black' }}>Candidatos</p>
                                        </div>
                                    </div>
                                </div>
                                {this.state.tab=='cursos' && <CursosProcesso processo_id={this.state.processo.id}/>}
                                {/* {this.state.tab=='cursos' && <OfertasAndamento curso_id={this.props.match.params.id}/>}
                                {this.state.tab=='candidatos' && <OfertasFinalizadas curso_id={this.props.match.params.id}/>} */}


                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(VerProcesso);


