
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../actions/AppActions';
import { connect } from 'react-redux';
import JoditEditor from "jodit-react";

// users
import moment from 'moment';
import { URL } from '../variables';
import ReactPaginate from 'react-paginate';

class AdicionarCursoProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/painel/ver_processo',
            loading_screen: true,
            name: '',
            loading_save: false,
            msgErro: '',
            cursos:[],
            curso:{},
            processo:{}
        };
        this.config = {
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            language:'pt_br'
        }
    }



    salvar() {
        let data={
            curso: this.state.curso,
            processo_id: this.props.match.params.id,

        };
        console.log(data);

        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/salvar_curso_processo`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Cursos');

        this.get_curso(this.props.match.params.id);
    }

    get_curso(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_cursos_list/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.props.mudarTitle('Cursos - ' + resp.processo.name);
                    let curso_id='';
                    if(resp.cursos.length>0){
                        curso_id=resp.cursos[0].id
                    }
                    this.setState({ loading_screen: false, 
                        cursos:resp.cursos, 
                        processo:resp.processo,
                        curso:curso_id
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }







    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path+'/'+this.props.match.params.id} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Adicionar Curso</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Adicione um curso ao processo seletivo {this.state.processo.name}</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                    <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Selecione um Curso</label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.curso} className="custom-select" id="cameras" onChange={(e)=>this.setState({curso:e.target.value})}>
                                                    {this.state.cursos.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                       
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to={"/painel/ver_processo/"+this.props.match.params.id}> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar curso</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(AdicionarCursoProcesso);


