import React from 'react';

const INITIAL_STATE = {
    token: null,
    user: {},
    loading_home: true,
    title:''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'title':
            return {
                ...state,
                title: action.payload,
            };
        case 'loading_home':
            return {
                ...state,
                loading_home: action.payload,
            };
        case 'logout':
            return {
                ...state,
                token: '',
                user: {},
            };
        case 'user':
            return {
                ...state,
                user: action.payload,
            };
        case 'login':
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,

            };
        default:
            return { ...state };
    }
};
