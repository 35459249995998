import React from 'react';
import { URL } from '../variables';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/AppActions';
import { maskCpf, maskNascimento } from '../Auxiliar/Masks';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            msgErro: '',
            cpf: '',
            nascimento: '',
            redirect: false,
            path: '',
            regexp: /^[0-9\b]+$/,

        }

    }

    login(event) {
        event.preventDefault();

        fetch(`${URL}api/auth/login`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cpf: this.state.cpf,
                password: this.state.nascimento.replace(/\//g, ''),
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro, });
                }
                else {
                    localStorage.setItem('token', resp.access_token);
                    this.props.login({ token: resp.access_token, user: {} });


                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', });
            });
    }


    componentDidMount() {
    }

    changeCpf(e) {
        let text = e.target.value
        if (e.target.value === '' || this.state.regexp.test(e.target.value.replace(/\./g, '').replace(/\-/g, ''))) {
            this.setState({ cpf: maskCpf(text) });
        }
    }

    changeNascimento(e) {
        if (e.target.value === '' || this.state.regexp.test(e.target.value.replace(/\//g, ''))) {
            this.setState({ nascimento: maskNascimento(e.target.value) })
        }
    }

    render() {
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                    <span className="sr-only">Loading...</span>
                </div></div>}
                {!this.state.loading && <div className="row" style={{ backgroundColor: 'white',minHeight: '100vh' }}>
                    <div className="col-12 " style={{ margin: 0, padding: 0,justifyContent:'center',alignItems:'center',display:'flex',backgroundImage: `url(${require("../assets/images/bg_vestibular.jpeg")})`, backgroundSize: 'cover' }}>
                        <div className="card" style={{  margin: 0,maxWidth:500,width:'100%' }}>
                            <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>


                                <div className="text-center">
                                    <a className="logo"><img src={require('../assets/images/logo_enter.png')}
                                        height="80" alt="logo" /></a>
                                </div>

                                <div style={{ padding: '1rem', paddingBottom: 0 }}>
                                    <h4 className="font-18 m-b-5 text-center">Bem vindo !</h4>
                                    <p className="text-muted text-center">Faça login para continuar.</p>

                                    <form className="form-horizontal m-t-30" onSubmit={this.login.bind(this)}>

                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label >CPF</label>
                                                <input id="credencial" type="username"
                                                    className="form-control  " name="cpf"
                                                    value={this.state.cpf} onChange={this.changeCpf.bind(this)} required autoComplete="email" autoFocus />

                                            </div>

                                        </div>

                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label >Data de Nascimento</label>
                                                <input type="text"
                                                    value={this.state.nascimento}
                                                    onChange={this.changeNascimento.bind(this)}
                                                    className="form-control" name="password"
                                                    required autoComplete="current-password" />
                                            </div>

                                        </div>

                                        <div className="form-group row m-t-20">
                                        <div className="col-sm-12">
                                                <span className="invalid-feedback" style={{display:'flex',justifyContent:'center'}} role="alert">
                                                    <strong style={{textAlign:'center',fontSize:'0.8rem'}}>{this.state.msgErro}</strong>
                                                </span>
                                            </div>
                                            <div className="col-sm-12 text-right">
                                                <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem' }} type="submit">Entrar</button>

                                            </div>
                                        </div>

                                        <div className="form-group m-t-10 mb-0 row">
                                            <div className="col-12 m-t-20" style={{ textAlign: 'center' }}>


                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-center">
                                    <p>© 2020 OnTeste</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div className="col-md-6 col-sm-6 col-12 col-lg-8 col-xl-8 d-none d-md-block" style={{ overflow: 'hidden', backgroundImage: `url(${require("../assets/images/bg_vestibular.jpeg")})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                    </div> */}
                </div>}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { login })(Login);
