
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser ,mudarTitle} from '../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../variables';

class MenuItens extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            nao_iniciado: false,
            regras_processo: false,
            andamento: false,
            finalizada: false,
            loading_screen: true
        };
    }





    componentDidMount() {
        this.props.mudarTitle('Escolha uma opção');
    }




    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/usuarios"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-users" style={{ fontSize: '1.3rem', marginRight: '1rem' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Usuários</p>
                    </div></Link>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/painel/cursos"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="mdi mdi-folder-account-outline" style={{ fontSize: '1.8rem', marginRight: '1rem' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Cursos</p>
                    </div></Link>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/painel/processos"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="mdi mdi-book-open-page-variant" style={{ fontSize: '1.8rem', marginRight: '1rem' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Processos Seletivos</p>
                    </div></Link>
                </div>
               
                {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/disciplinas"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="mdi mdi-book-open-page-variant" style={{ fontSize: '1.8rem', marginRight: '1rem' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Disciplinas</p>
                    </div></Link>
                </div> */}
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser,mudarTitle })(MenuItens);


