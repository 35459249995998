import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Router } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import Template from "./Auth/Template";
import Home from "./Home/Home";

import Login from "./Auth/Login";
import { useSelector, useDispatch } from 'react-redux'


const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.AppReducer.token)

    return (
        <Route
            {...rest}
            render={props =>
                token != null ? (
                    <Component {...props}>
                        {/* {processo != null && <div>
                            
                        </div>}
                        {/* {processo==null && <OtherRoute path={`${props.match.url}*`}  path_name={`${props.match.url}not_processo`}/>} */}
                    </Component>
                ) : (
                        // <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                        // <Informacoes {...props} />
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

                    )
            }
        />)
};

const LoginRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.AppReducer.token)
    return (
        <Route
            {...rest}
            render={props =>
                token == null ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{ pathname: "/painel", state: { from: props.location } }} />
                    )
            }
        >

        </Route>);
};

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);

const Routes = () => {
    const token = useSelector(state => state.AppReducer.token)
    return (
        <BrowserRouter>
            <Switch>
                <LoginRoute path="/login" exact component={(props) => <Login {...props} />} />
                <PrivateRoute path="/painel" component={(props) => <Home {...props} />} />

                <Route  path="/" component={(props) => <Template {...props} />} />

                {/* <Route exact path="/:slug/login" component={(props) => isAuthenticated() == false ? <Login {...props} /> : <Route render={props => <Redirect to={{ pathname: "/", state: { from: props.location } }} />}
            />} /> */}
                {/* <LoginRoute exact path="/" component={(props) => <Informacoes {...props} />} /> */}


                {/* <OtherRoute path="*" path_name='/' /> */}
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;