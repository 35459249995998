
import React, { Component } from 'react';
import { connect } from 'react-redux';

// users

class PageTitle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <div className="page-title-box" style={{marginTop:'6rem',marginBottom:'3rem'}}>
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <h4 style={{ color: 'black', textAlign: 'center' }}>{this.props.title}</h4>
                    </div>
                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        title: state.AppReducer.title,
    }
);

export default connect(mapsStateToProps, {})(PageTitle);


