import React from 'react';
import { URL, URL_GESTAO } from '../variables';
import { maskCpf, maskRg, maskTelefone, maskCep, maskPrice } from '../Auxiliar/Masks';
import { Redirect } from 'react-router';

class Cadastro extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_save: false,
            msgErro: '',
            redirect: false,
            path: '/',
            redirect: false,
            generos: [
                'Masculino',
                'Feminino',
                'Personalizado',
            ],
            estados_civis: [
                'Casado',
                'Solteiro',
                'Divorciado',
                'Viúvo',
                'Outro',
            ],
            lista_cursos: [],
            polos: [],
            cursos: [],
            show_radio_nivel: false,
            show_radio_turno: false,
            show_check_bolsa: false,
            nivel: '',
            tipo_nivel: 'ambos',
            polo_id: '',
            curso_id: '',
            turno: '',
            bolsa: false,
            nome: '',
            nomeSocial: "",
            genero: '',
            genero_personalizado: '',

            nascimento: '',
            cpf: '',
            rg: '',
            email: '',
            celular: '',
            estado_civil: '',
            pai: '',
            mae: '',
            cep: '',
            logradouro: '',
            complemento: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            ouveMusica: false,
            textoMusica: "",
            observacoes: '',
            preco: '',
            inscricao: false,
            regexp: /^[0-9\b]+$/,
            cursos: [],
            cursoSelected: {},
            modalidade: '',
            qual_deficiencia: '',
            deficiencia: false,
            pronome:''
        }
    }


    Salvar() {

        this.setState({ loading_save: true, msgErro: '' });

        fetch(`${URL}api/auth/store_registration`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                polo_id: this.state.polo_id,
                curso_id: this.state.curso_id,
                modalidade: this.state.modalidade,
                turno: this.state.turno,
                bolsa: this.state.bolsa,
                nome: this.state.nome,
                genero: this.state.genero,
                genero_personalizado: this.state.genero_personalizado,
                pronome: this.state.pronome,


                nascimento: this.state.nascimento,
                cpf: this.state.cpf,
                rg: this.state.rg,
                email: this.state.email,
                celular: this.state.celular,
                estado_civil: this.state.estado_civil,
                pai: this.state.pai,
                mae: this.state.mae,
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                complemento: this.state.complemento,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                observacoes: this.state.observacoes,
                nome_social: this.state.nomeSocial,
                texto_musica: this.state.textoMusica,
                ouve_musica: this.state.ouveMusica,

                deficiencia: this.state.deficiencia,
                qual_deficiencia: this.state.qual_deficiencia,

            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.setState({ loading_save: false, inscricao: true },
                        () => {
                            setTimeout(() => {
                                this.setState({ redirect: true });
                            }, 1000)
                        }
                    );
                }
            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    get_data() {
        this.setState({ loading: true });
        fetch(`${URL}api/auth/get_data_registration`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro, refresh: false, loadingMore: false });
                }
                else {

                    this.setState({ loading: false, msgErro: '', cursos: resp.cursos });
                }
            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });
            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
            });
    }

    componentDidMount() {
        // this.setState({ loading: true, msgErro: '' });
        window.scrollTo(500, 0);
        this.get_data();
    }



    handleSelect(selectedIndex, e) {
        this.setState({ indexSlide: selectedIndex });
    };

    buscar_endereco(cep) {
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.error != true) {
                    console.log(resp);
                    this.setState({
                        logradouro: resp.logradouro,
                        bairro: resp.bairro,
                        cidade: resp.localidade,
                        estado: resp.uf,
                        numero: ''
                    });
                }

            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }


    render() {
        return (
            <div style={{ marginBottom: 100, marginTop: 30, minHeight: '60vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <main style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <div className="container" style={{ backgroundColor: 'white', borderRadius: 10 }}>
                            <br />

                            <h4 className=" header-title" style={{ fontSize: '1.5rem', color: 'rgb(255, 0, 102)' }}>Inscrição Itec</h4>
                            <p style={{ fontSize: '1rem', color: 'black', marginBottom: '20px' }}>Preencha o formulário para participar do Itec</p>
                            {this.state.loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 100, paddingTop: 100 }}> <div className="spinner-border text-primary" role="status"  >
                                <span className="sr-only">Loading...</span>
                            </div></div>}

                            {this.state.loading == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: ('') })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                                    </div>
                                    <br />
                                </div>}
                                <div className="row" >
                                    <div className="col-12">
                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                fontWeight: 'bold'
                                            }}>INFORMAÇÕES GERAIS</p>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Curso <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.curso_id} className="form-select" onChange={(e) => {
                                                    if (e.target.value == '') {
                                                        this.setState({ polos: [], curso_id: e.target.value, polo_id: '', modalidade: '' });
                                                    }
                                                    else {
                                                        let curso = this.state.cursos.filter((item, id) => item.id == e.target.value)[0];
                                                        let polos = curso.polos_cursos.map((item, id) => item.polos);
                                                        console.log(polos);
                                                        this.setState({ polos: polos, curso_id: e.target.value, cursoSelected: curso, polo_id: '', modalidade: '' })
                                                    }
                                                }}>
                                                    <option value={''}>Selecione um curso</option>
                                                    {this.state.cursos.map((item, id) => (<option key={id} value={item.id}>{item.nome} - {item.nivel}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.curso_id != '' && this.state.cursoSelected.nivel == 'Técnico' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Polo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.polo_id} className="form-select" onChange={(e) => {
                                                    this.setState({ polo_id: e.target.value });
                                                }}>
                                                    <option value={''}>Selecione um polo</option>
                                                    {this.state.polos.map((item, id) => (<option key={id} value={item.id}>{item.instituicao} - {item.cidade}</option>))}
                                                </select>
                                            </div>
                                        </div>}

                                        {this.state.curso_id != '' && this.state.cursoSelected.nivel == 'Graduação' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Modalidade <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.modalidade} className="form-select" onChange={(e) => {
                                                    this.setState({ modalidade: e.target.value });
                                                }}>
                                                    <option value={''}>Selecione uma modalidade</option>
                                                    <option value={'Vestibular Online'}>Vestibular Online</option>
                                                    <option value={'Vestibular Presencial'}>Vestibular Presencial (Patos)</option>

                                                </select>
                                            </div>
                                        </div>}
                                        {/*   <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Polo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.polo_id} className="form-select" onChange={(e) => {
                                                    if (e.target.value != '') {
                                                        let cursos = this.state.polos.filter((item) => item.id == e.target.value).map((item2) => item2.polos_cursos);
                                                        let graduacao = cursos[0].filter((item) => item.curso.nivel == 'Graduação');
                                                        let tecnico = cursos[0].filter((item) => item.curso.nivel == 'Técnico');
                                                        console.log(graduacao.length)
                                                        if (graduacao.length > 0 && tecnico.length > 0) {
                                                            this.setState({ tipo_nivel: 'ambos', polo_id: e.target.value, show_radio_turno: false, show_check_bolsa: false, cursos: cursos[0], show_radio_nivel: true });
                                                        } else if (graduacao.length > 0 && tecnico.length == 0) {
                                                            this.setState({ tipo_nivel: 'de graduação', polo_id: e.target.value, cursos: graduacao, show_radio_turno: false, show_check_bolsa: false, show_radio_nivel: false });
                                                        } else {
                                                            this.setState({ tipo_nivel: 'técnico', polo_id: e.target.value, cursos: tecnico, show_radio_turno: false, show_check_bolsa: false, show_radio_nivel: false });
                                                        }
                                                    }
                                                    else {
                                                        this.setState({ tipo_nivel: 'ambos', polo_id: '', cursos: [], show_radio_nivel: false, show_radio_turno: false, show_check_bolsa: false });

                                                    }
                                                }}>
                                                    <option value={''}>Selecione um polo</option>
                                                    {this.state.polos.map((item, id) => (<option key={id} value={item.id}>{item.instituicao} - {item.cidade}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <hr /> */}

                                        {/* {this.state.polo_id != '' && <div className="form-group">
                                            <div>CURSO</div>
                                            {this.state.show_radio_nivel == true &&

                                                <div className="form-check  row">
                                                    <br />
                                                    <label className="form-check-label">O que você pretende fazer? <b style={{ color: 'red' }}>*</b></label>
                                                    <div className=" col-sm-9">
                                                        <div className="row " style={{ marginLeft: '10px' }} >
                                                            <div className="col-md-3 " >
                                                                <input className="form-check-input " name="nivel" id="curso_tecnico" type="radio"
                                                                    value="Técncico"
                                                                    onChange={(e) => {
                                                                        let cursos = this.state.polos.filter((item) => item.id == this.state.polo_id).map((item2) => item2.polos_cursos);
                                                                        let curso_selected = cursos[0].filter((item) => item.curso.nivel == 'Técnico');
                                                                        console.log(curso_selected);
                                                                        this.setState({ nivel: e.target.value, tipo_nivel: 'técnico', cursos: curso_selected, show_radio_turno: false, show_check_bolsa: false, })
                                                                    }}
                                                                />
                                                                <label className=" form-check-label " htmlFor="curso_tecnico" style={{ fontWeight: 'normal' }} >Curso técnico</label>
                                                            </div>
                                                            <div className="col-md-2" >
                                                                <input className="form-check-input" name="nivel" id="graduacao" type="radio"
                                                                    value="Graduação"
                                                                    onChange={(e) => {
                                                                        let cursos = this.state.polos.filter((item) => item.id == this.state.polo_id).map((item2) => item2.polos_cursos);
                                                                        let curso_selected = cursos[0].filter((item) => item.curso.nivel == 'Graduação');
                                                                        console.log(curso_selected)
                                                                        this.setState({ nivel: e.target.value, tipo_nivel: 'de graduação', cursos: curso_selected, show_radio_turno: false, show_check_bolsa: false, })
                                                                    }} />
                                                                <label className=" form-check-label" htmlFor="graduacao" style={{ fontWeight: 'normal' }}>Graduação</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            <br />

                                            {this.state.tipo_nivel != 'ambos' &&
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ color: 'black' }} style={{ color: 'black' }}>Curso {this.state.tipo_nivel} <b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <select value={this.state.curso_id} className="form-select" onChange={(e) => {
                                                            if (e.target.value != '') {
                                                                let curso = this.state.cursos.filter((item) => item.id == e.target.value);
                                                                console.log(curso);
                                                                if (curso[0].turnos == 'Todos') {
                                                                    if (curso[0].bolsistas == 'Sim') {
                                                                        this.setState({ curso_id: e.target.value, show_radio_turno: true, show_check_bolsa: true, preco: curso[0].preco });
                                                                    }
                                                                    else { this.setState({ curso_id: e.target.value, show_radio_turno: true, show_check_bolsa: false, preco: curso[0].preco }); }
                                                                } else {
                                                                    if (curso[0].bolsistas == 'Sim') {
                                                                        this.setState({ curso_id: e.target.value, show_radio_turno: false, show_check_bolsa: true, turno: curso[0].turnos, preco: curso[0].preco });
                                                                    }
                                                                    else { this.setState({ curso_id: e.target.value, show_radio_turno: false, show_check_bolsa: false, turno: curso[0].turnos, preco: curso[0].preco }); }
                                                                }
                                                            } else {
                                                                this.setState({ show_radio_turno: false, show_check_bolsa: false });

                                                            }
                                                        }}>
                                                            <option value={''}>Selecione um curso</option>
                                                            {this.state.cursos.map((item, id) => (<option key={id} value={item.id}>{item.curso.nome}{item.turnos != 'Todos' ? ' - ' + item.turnos : false}</option>))}
                                                        </select>
                                                    </div>
                                                </div>}

                                            {this.state.show_check_bolsa == true &&
                                                <div className="form-check  row">
                                                    <br />
                                                    <label className="form-check-label">Bolsa de estudos</label>
                                                    <div className=" col-sm-12">
                                                        <div className="row " style={{ marginLeft: '10px' }} >
                                                            <div className="col-md-12 " >
                                                                <input className="form-check-input " name="bolsa" id="bolsa" type="checkbox"
                                                                    value={this.state.bolsa}
                                                                    onChange={(e) => {
                                                                        this.setState({ bolsa: true });
                                                                    }}
                                                                />
                                                                <label className=" form-check-label " htmlFor="bolsa" style={{ fontWeight: 'normal' }} >Quero me candidatar a uma bolsa e concordo que meu cadastro será analisado e no prazo de até 5 dias úteis terei a resposta.</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </div>}

                                            {this.state.show_radio_turno == true &&
                                                <div className="form-check  row">
                                                    <label className="form-check-label">Turno <b style={{ color: 'red' }}>*</b></label>
                                                    <div className=" col-sm-9">
                                                        <div className="row " style={{ marginLeft: '10px' }} >
                                                            <div className="col-md-2 " >
                                                                <input className="form-check-input " name="turno" id="Manha" type="radio"
                                                                    value="Manhã"
                                                                    onChange={(e) => {
                                                                        this.setState({ turno: e.target.value });
                                                                    }}
                                                                />
                                                                <label className=" form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal' }} >Manhã</label>
                                                            </div>
                                                            <div className="col-md-2" >
                                                                <input className="form-check-input" name="turno" id="Tarde" type="radio"
                                                                    value="Tarde"
                                                                    onChange={(e) => {
                                                                        this.setState({ turno: e.target.value });
                                                                    }} />
                                                                <label className=" form-check-label" htmlFor="Tarde" style={{ fontWeight: 'normal' }}>Tarde</label>
                                                            </div>
                                                            <div className="col-md-2" >
                                                                <input className="form-check-input" name="turno" id="Noite" type="radio"
                                                                    value="Noite"
                                                                    onChange={(e) => {
                                                                        this.setState({ turno: e.target.value });
                                                                    }} />
                                                                <label className=" form-check-label" htmlFor="Noite" style={{ fontWeight: 'normal' }}>Noite</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            <hr />
                                        </div>} */}
                                        <hr style={{ marginTop: 30 }} />
                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                fontWeight: 'bold'
                                            }}>INFORMAÇÕES PESSOAIS</p>
                                        </div>
                                        {/* <br /> */}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Seu nome completo " value={this.state.nome} type="text" onChange={(e) => { this.setState({ nome: e.target.value }) }} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome Social</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Seu nome social" value={this.state.nomeSocial} type="text" onChange={(e) => this.setState({ nomeSocial: e.target.value })} />
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-12 ">
                                                        <label className="form-label col-form-label" style={{ color: 'black' }}>Gênero <b style={{ color: 'red' }}>*</b></label>
                                                        {/* <select value={this.state.genero} className="form-select" onChange={(e) => {
                                                            this.setState({ genero: e.target.value });
                                                        }}>
                                                            <option value={''}>Selecione uma opção</option>
                                                            {this.state.generos.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                                                        </select> */}

                                                        <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                                <div style={{ width: 110, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10,color:'black' }}>Masculino</label>

                                                                    <input className="form-check-input" type="radio" name="sexo" checked={this.state.genero == 'Masculino'} onChange={() => this.setState({ genero: 'Masculino',pronome:'',genero_personalizado:'' })} />
                                                                </div>
                                                                <div style={{ width: 110, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10,color:'black' }}>Feminino</label>

                                                                    <input className="form-check-input" type="radio" name="sexo" checked={this.state.genero == 'Feminino'} onChange={(e) => this.setState({ genero: 'Feminino',pronome:'',genero_personalizado:'' })} />
                                                                </div>
                                                                <div style={{ width: 130, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10,color:'black' }}>Personalizado</label>

                                                                    <input className="form-check-input" type="radio" name="sexo" checked={this.state.genero == 'Personalizado'} onChange={(e) => this.setState({ genero: 'Personalizado',pronome:'',genero_personalizado:'' })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {this.state.genero=='Personalizado' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Personalizado <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.genero_personalizado} className="form-select" onChange={(e) => {
                                                    this.setState({ genero_personalizado: e.target.value });
                                                }}>
                                                    <option value={''}>Selecione seu pronome</option>
                                                    <option value={'Feminino'}>Feminino: "Deseje a ela um feliz aniversário!"</option>
                                                    <option value={'Masculino'}>Masculino: "Deseje a ele um feliz aniversário!"</option>
                                                    <option value={'Neutro'}>Neutro: "Deseje a ele(a) um feliz aniversário!"</option>

                                                </select>
                                            </div>
                                        </div>}
                                        {this.state.genero=='Personalizado' &&<div className="form-group row mt-2">
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Gênero (Opcional)" value={this.state.pronome} type="text" onChange={(e) => { this.setState({ pronome: e.target.value }) }} />
                                            </div>
                                        </div>}

                                        <div className="form-group  row">
                                            <div className="col-md-12 ">
                                                <label className="form-label col-form-label" style={{ color: 'black' }}>Data de nascimento <b style={{ color: 'red' }}>*</b></label>
                                                <input className="form-control" id="data" type="date" onChange={(e) => this.setState({ nascimento: e.target.value })} value={this.state.nascimento} />
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>CPF <b style={{ color: 'red' }}>*</b></label>
                                                        <input className="form-control" id="nome" placeholder="Número do seu CPF" type="text" onChange={(e) => this.setState({ cpf: maskCpf(e.target.value) })} value={this.state.cpf} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Identidade <b style={{ color: 'red' }}>*</b></label>
                                                        <input className="form-control" placeholder="Número do seu RG" id="identidade" type="text" onChange={(e) => this.setState({ rg: maskRg(e.target.value) })} value={this.state.rg} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>E-mail <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" id="email" placeholder="Seu e-mail" value={this.state.email} type="e-mail" onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-4 ">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Celular <b style={{ color: 'red' }}>*</b></label>
                                                        <input className="form-control" placeholder="(xx) xxxxx-xxxx" id="celular" type="tel" onChange={(e) => this.setState({ celular: maskTelefone(e.target.value) })} value={this.state.celular} />
                                                    </div>
                                                    <div className="col-md-8 ">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Estado Civil <b style={{ color: 'red' }}>*</b></label>
                                                        <select value={this.state.estado_civil} className="form-select" onChange={(e) => {
                                                            this.setState({ estado_civil: e.target.value });
                                                        }}>
                                                            <option value={''}>Selecione uma opção</option>
                                                            {this.state.estados_civis.map((item, index) => (<option key={index} value={item}>{item + '(a)'}</option>))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome do Pai <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" id="pai" placeholder="Nome do seu pai" value={this.state.pai} type="text" onChange={(e) => {
                                                    this.setState({ pai: e.target.value });
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome da mãe <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" id="mae" placeholder="Nome da sua mãe" value={this.state.mae} type="text" onChange={(e) => {
                                                    this.setState({ mae: e.target.value });
                                                }} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Possui alguma deficiência? <b style={{ color: 'red' }}>*</b></label>
                                            <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="deficiencia" checked={this.state.deficiencia == true} onChange={() => this.setState({ deficiencia: true })} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Sim</label>
                                                    </div>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="deficiencia" checked={this.state.deficiencia == false} onChange={(e) => this.setState({ deficiencia: false })} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Não</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mt-2" hidden={!this.state.deficiencia}>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Explique qual(is) deficiência(s) você possui"
                                                    value={this.state.qual_deficiencia}
                                                    type="text"
                                                    onChange={(e) => this.setState({ qual_deficiencia: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Você gosta de ouvir música enquanto estuda ou faz avaliação? </label>
                                            <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="likesMusic" checked={this.state.ouveMusica == true} onChange={() => this.setState({ ouveMusica: true })} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Sim</label>
                                                    </div>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="likesMusic" checked={this.state.ouveMusica == false} onChange={(e) => this.setState({ ouveMusica: false })} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Não</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mt-2" hidden={!this.state.ouveMusica}>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Coloque aqui duas musicas (nome da música e cantor) que você gosta de ouvir enquanto estuda"
                                                    value={this.state.textoMusica}
                                                    type="text"
                                                    onChange={(e) => this.setState({ textoMusica: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row" hidden={!this.state.ouveMusica}>
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Deseja dizer algo mais? </label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Comentários..." value={this.state.observacoes} type="text" onChange={(e) => { this.setState({ observacoes: e.target.value }) }} />
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: 30 }} />
                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                marginTop: 20,
                                                fontWeight: 'bold'
                                            }}>ENDEREÇO</p>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CEP <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" id="cep" placeholder="CEP" value={this.state.cep} type="text" onChange={(e) => {
                                                    this.setState({ cep: maskCep(e.target.value) });
                                                    let text = e.target.value.replace('-', '');
                                                    if (text.length == 8) {
                                                        this.buscar_endereco(text);
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Logradouro <b style={{ color: 'red' }}>*</b></label>
                                                        <input className="form-control" id="rua" placeholder="Logradouro" type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} />
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Número </label>
                                                        <input className="form-control" id="numero" placeholder="Número" type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Bairro <b style={{ color: 'red' }}>*</b></label>
                                                        <input className="form-control" placeholder="Nome do bairro" type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} />
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Complemento</label>
                                                        <input className="form-control" placeholder="Complemento" type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-10 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Cidade <b style={{ color: 'red' }}>*</b></label>
                                                        <input className="form-control" disabled placeholder="Nome da cidade" type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} />
                                                    </div>
                                                    <div className="col-md-2 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Estado<b style={{ color: 'red' }}>*</b></label>
                                                        <input className="form-control" disabled placeholder="UF" type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row mt-3">
                                            {(this.state.inscricao == false && this.state.loading_save == false) && <div className="col-12 col-sm-12 d-flex justify-content-center justify-content-sm-end"
                                                style={{ marginBottom: '0.5rem' }}>                                            <hr />
                                                <button
                                                    className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                                                    type="button"
                                                    onClick={(e) => this.Salvar()}
                                                    style={{
                                                        backgroundColor: "rgb(255, 0, 102)",
                                                        borderColor: "rgb(255, 0, 102)"
                                                    }}
                                                >
                                                    Finalizar Inscrição
                                                </button>
                                            </div>}
                                            {this.state.inscricao == true && <div className="col-12 col-sm-12 d-flex justify-content-center justify-content-sm-end">
                                                <div className="alert alert-success d-flex align-items-center col-4 col-sm-4" role="alert">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Success:">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                    </svg>
                                                    <div>
                                                        Inscrição realizada com sucesso...
                                                    </div>
                                                </div></div>}
                                        </div>
                                        {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div></div>}
                                        {/* {this.state.preco != '' && <div>
                                            <hr />
                                            <div className="form-group ">
                                                <div style={{ textAlign: 'right' }}>
                                                    <label className="col-form-label" style={{ fontSize: '21px', fontWeight: 'bold', color: 'black' }} >{maskPrice(parseFloat(this.state.preco).toFixed(2))}</label>
                                                    <p >TOTAL</p>
                                                </div>
                                            </div></div>} */}

                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </main>

            </div>
        );
    }


}

export default Cadastro;
