import React from 'react';
import { URL } from '../variables';
import Carousel from 'react-bootstrap/Carousel'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Inicial extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      msgErro: '',
      redirect: false,
      indexSlide: 0,
      banners: [
        'https://i.pinimg.com/originals/36/a7/75/36a775ae41454fdac1efacdf6c76a839.jpg',
        'https://blog.ibmec.br/wp-content/uploads/2020/09/ON_IBMEC-INSIGHT_BANNER_GRAD-ADM_MOBILE_768X400PX.png',
        'https://blog.ibmec.br/wp-content/uploads/2020/09/ON_IBMEC-INSIGHT_BANNER_GRAD-ADM_MOBILE_768X400PX.png',
      ],
      lista_cursos: [
        'Matemática',
        'Direito',
        'Ciêcia da Computação',
        'Relações Internacionais',
        'Engenharia Mecânica',
        'Engenharia de Produção',
        'Engenharia de Computação',
        'Engenharia Civil',
        'Comunicação Social- Publicidade e Propaganda',
        'Ciências Econômicas',
        'Arquitetura e Urbanismo',
        'Administração',
      ],
      lista_cidades: [
        'Rio de Janeiro (RJ)',
        'Fortaleza (CE)',
        'Recife (PE)',
        'Salvador (BA)',
        'Brasília (DF)',
      ]
    }
  }


  // renderRedirect = () => {
  //   if (this.state.redirect) {
  //     return <Redirect to={'/'+this.state.faculdade_select.slug} />
  //   }
  // }

  get_data() {
    fetch(`${URL}api/get_banners`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        // 'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          this.setState({ loading: false, msgErro: erro, refresh: false, loadingMore: false });
        }
        else {
          let selected = '';
          if (resp.faculdades.length > 0) {

            selected = resp.faculdades[0].slug;
          }
          this.setState({ faculdades: resp.faculdades, loading: false, msgErro: '', faculdade_select: selected });
          // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

        }




      } catch (err) {
        console.log(err);
        this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

      }

    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
      });
  }

  componentDidMount() {
    // this.setState({ loading: true, msgErro: '' });
    // this.get_faculdades();
  }



  handleSelect(selectedIndex, e) {
    this.setState({ indexSlide: selectedIndex });
  };


  render() {
    return (
      <div style={{marginBottom:230}}>
        {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
          <span className="sr-only">Loading...</span>
        </div></div>}
        {this.state.loading == false && <div>
          <main style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
              <Carousel activeIndex={this.state.indexSlide} onSelect={this.handleSelect.bind(this)}>
                {this.state.banners.map((item, id) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      style={{ objectFit: 'contain', backgroundColor: 'rgba(0,0,0,0.9)', height: '500px' }}
                      src={item}
                      alt="First slide"
                    />

                  </Carousel.Item>
                ))}

              </Carousel>
              <div className="container" style={{ backgroundColor: 'white',borderBottomLeftRadius:10,borderBottomRightRadius:10 }}>
                <div className="row">
                  <div className="col-md-12">
                    <div style={{ padding: 20 }}>
                      <h3 style={{ color: 'rgb(255, 0, 102)' }}>Vestibular Ibmec</h3>
                      <div className="mt-5">
                        <p style={{ whiteSpace: 'pre-line', lineHeight: '30px', textAlign: 'justify' }}>
                          O formato do processo seletivo do IBMEC aproxima os candidatos da realidade que irão encontrar ao concluir a faculdade. Além de avaliar as competências técnicas (hard skills) necessárias para o ingresso no Ensino Superior, também considera as competências socioemocionais (soft skills). Assim, o Processo de Seleção de Talentos acontece em duas etapas: 1ª fase: Prova de múltipla escolha que avaliará os conhecimentos adquiridos no ensino médio; 2ª fase: Avaliação de competências socioemocionais por meio de dinâmicas focadas na resolução de problemas e estudos de caso. A qualquer momento, você pode visitar a unidade escolhida para uma visita monitorada e conversar com um consultor de carreiras.Venha mostrar seu talento.
                          </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr />

                    <div style={{ padding: 20 }}>
                      <h4 style={{ color: 'rgb(255, 0, 102)' }}>Cursos</h4>
                      <div className="row mt-5">
                        {this.state.lista_cursos.map((item, id) => (
                          <div className="col-md-6 col-12">
                            <p style={{ whiteSpace: 'pre-line', lineHeight: '30px', textAlign: 'justify' }}>
                              {item}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div style={{ padding: 20 }}>
                      <h4 style={{ color: 'rgb(255, 0, 102)' }}>Cidades Aplicadas</h4>
                      <div className="row mt-5">
                        {this.state.lista_cidades.map((item, id) => (
                          <div className="col-md-6 col-12">
                            <p style={{ whiteSpace: 'pre-line', lineHeight: '30px', textAlign: 'justify' }}>
                              {item}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12 ">
                         <Link to="/inscricao"><button className="btn btn-primary btn-lg btn-block waves-effect waves-light" style={{ backgroundColor: "rgb(255, 0, 102)", borderColor: "rgb(255, 0, 102)" }} type="button">Faça sua Inscrição</button>
                         </Link>
                      </div>
                    </div>
                   
                  </div>

                </div>
              </div>
            </div>
          </main>
        </div>}
      </div>
    );
  }


}

export default Inicial;
