import React from 'react';
import Header from './header';
import { connect } from 'react-redux';
import { logout, mudarUser,  mudarLoadingHome } from '../actions/AppActions';
import { URL } from '../variables';
// import io from 'socket.io-client';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MenuItens from './MenuItens';
import PageTitle from './PageTitle';
import Processos from '../processos/Processos';
import CriarProcesso from '../processos/CriarProcesso';
import EditarProcesso from '../processos/EditarProcesso';
import Cursos from '../cursos/Cursos';
import CriarCurso from '../cursos/CriarCurso';
import EditarCurso from '../cursos/EditarCurso';
import VerProcesso from '../processos/VerProcesso';
import AdicionarCursoProcesso from '../processos/AdicionarCursoProcesso';

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    }
  />
);
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // redirect:'/not_processo',

    }
    this.videoRef = React.createRef();

    // this.videoRef=null;
  }
  logout() {
    localStorage.removeItem('token');
    // this.setState({redirect:true,path:'/'});
    this.props.logout();
  }

  componentDidMount() {
    this.props.mudarLoadingHome(true);

    this.get_user();




  }

  get_user() {
    fetch(`${URL}api/get_user/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          this.props.mudarLoadingHome(false);
        }
        else {
          this.props.mudarUser(resp.user);

          this.props.mudarLoadingHome(false);

        }




      } catch (err) {
        console.log(err);
        this.props.mudarLoadingHome(false);

      }

    })
      .catch((err) => {
        console.log(err);
        this.props.mudarLoadingHome(false);
      });
  }



  render() {
    return (
      <div>
        {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

        <div id="wrapper">
          <Header />
          {this.props.loading_home == false && <div>

            <div className="content-page" style={{ marginLeft: 0 }}>
              <div className="content">

                <div className="container-fluid">
                  <PageTitle />

                  {/* {this.props.processo != null &&
                   } */}
                  <Switch>
                    <Route exact path={`${this.props.match.url}`} render={(props2) => <MenuItens {...props2} />} />
                    <Route exact path={`${this.props.match.url}/processos`} render={(props2) => <Processos {...props2} />} />
                    <Route exact path={`${this.props.match.url}/processos/criar`} render={(props2) => <CriarProcesso {...props2} />} />
                    <Route exact path={`${this.props.match.url}/processos/editar/:id`} render={(props2) => <EditarProcesso {...props2} />} />
                    <Route exact path={`${this.props.match.url}/ver_processo/:id`} render={(props2) => <VerProcesso {...props2} />} /> 
                    <Route exact path={`${this.props.match.url}/adicionar_curso_processo/:id`} render={(props2) => <AdicionarCursoProcesso {...props2} />} /> 

                    <Route exact path={`${this.props.match.url}/cursos`} render={(props2) => <Cursos {...props2} />} />
                    <Route exact path={`${this.props.match.url}/cursos/criar`} render={(props2) => <CriarCurso {...props2} />} />
                    <Route exact path={`${this.props.match.url}/cursos/editar/:id`} render={(props2) => <EditarCurso {...props2} />} />
                    {/* //  <Route exact path={`${this.props.match.url}/ver_curso/:id`} render={(props2) => <VerCurso {...props2} />} />  */}
                  </Switch>
                  {/* { this.props.children } */}
                  {/* {this.props.processo == null &&
                    <NotProcesso />} */}
                </div>
              </div>
            </div>
          </div>}
          {this.props.loading_home == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="spinner-border text-primary" role="status"  >
              <span className="sr-only">Loading...</span>
            </div>
          </div>}

        </div>

      </div >

    );
  }


}

const mapsStateToProps = (state) => (
  {
    faculdade: state.AppReducer.faculdade,
    token: state.AppReducer.token,
    loading_home: state.AppReducer.loading_home,
    stream: state.AppReducer.stream,
    processo: state.AppReducer.processo,
  }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarLoadingHome })(Home);
